var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      class: "" + (_vm.isClick ? "tag" : "noneBorder"),
      style: _vm.color,
      on: { click: _vm.clickTag },
    },
    [_vm._v(" " + _vm._s(_vm.typeList[_vm.type].name) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }